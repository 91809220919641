.background_color{
    background: linear-gradient(to bottom,  #097969 16%,#737373 100%);
    /* height: 100vh; */
}


.logo_divider{
    border-color: black !important;
}

.login_button{
    color: #fff !important;
}

.icon_size{
    font-size: 1.25rem !important;
}

.icon_height{
    height: 3rem;
    margin-top: 7px;
}

.login_heading {
    font-family: 'Poppins' !important;
  }