.accordian_heading{
    background-color: #D9D9DC !important;
    min-height: 44px !important;
}

.fields_padding{
    padding-top: 0px !important;
}

.total_calls_icon{
    color: #ed4264; /* Set gradient as background */
}

.font_size{
    font-size: 2.5rem !important;
}