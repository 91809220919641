.fields_padding{
    padding-top: 0px !important;
}

.field_padding_personal{
    padding-top: 6px !important;
    border: 1px #D9D9DC solid;
    border-radius: 2px;
}

.form_card_style{
    width: auto;
    margin: auto;
}

.button_space_style{
    margin: 3px !important;
}

.button_space{
    margin: 5px !important;
}

/* .accordian_width{
    width: 100%;
} */

.table_container {
    overflow-x: auto;
     max-width: 100%; /*Optional: Set maximum width for the table container */
  }

.table {
    /* width: 100%; */
    border-collapse: collapse; /* Ensures table borders are collapsed */
    border: 1px solid #ccc; /* Overall border for the table */
    /* Example minimum width to trigger horizontal scroll */
  }
  
.table th,
.table td {
  border: 1px solid #ccc; /* Borders for table header and cells */
  padding: 8px;
  text-align: left;
}

.table th{
    font-size: 15px;
}



  
.custom_required_label .MuiFormLabel-root.Mui-required::after {
    content: '*'; /* Custom star */
    color: red;   /* Custom color */
    font-weight: bold;
    margin-left: 0.2rem; /* Adjust as needed */
  }

  .custom_required_star::after {
    content: ' *'; /* Adds your custom required star */
    color: red; /* Change this to your desired color */
    font-weight: bold;
  }


  .statusContainer {
    margin-bottom: 8px; /* Adjust this value as needed */
  }
  
  .statusPaper {
    padding: 8px; /* Adjust this value as needed */
  }