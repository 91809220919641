.accordian_heading{
    background-color: #D9D9DC !important;
}

.label_margin{
    margin-right: 10px !important;
    font-weight: 700 !important;
}

.upload_div{
    margin-bottom: 5px !important;
}




















