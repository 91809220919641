.p_inside_divider{
    border-width: 1px !important;
    border-color: black !important;
    width: 100%;
}

.icon_font_size{
    font-size: 2.5rem !important;
    color: #4C7DE7;
}

.icon_button_style{
    border: 1px solid !important;
    border-radius: 3px !important;
    margin-left: 5px !important;
}