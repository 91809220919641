/* analyticstable.css */

/* Styles for the header cells */
.header-cell {
    position: relative;
    text-align: center;
    background-color: #EEF0F4;
    padding: 8px;
}

/* Styles for icons in header cells */
.header-cell .icon-container {
    opacity: 0; /* Initially hide icons */
    transition: opacity 0.3s ease;
    position: absolute;
    right: 5px;
    top: 5px;
}

/* Show icons on hover */
.header-cell:hover .icon-container {
    opacity: 1; /* Show icons when hovered */
}


.container {
    padding: 16px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.select {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
}

.title {
    font-size: 20px;
    margin-bottom: 10px;
}
