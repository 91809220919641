/* Styling DataTable headers */
.custom-datatable .p-datatable-thead > tr > th {
  background-color: #EEF0F4; /* Background color */
  color: #333; /* Text color */
  font-weight: bold; /* Font weight */
  text-align: center;
  height: 20px !important; /* Adjust the height as needed */
  padding: 2px; /* Adjust the padding as needed */
  line-height: 19px; /* Match the line height to the height for vertical centering */
}

.custom-datatable1 .p-datatable-thead > tr > th {
  background-color: #EEF0F4; /* Background color */
  color: #333; /* Text color */
  font-weight: bold; /* Font weight */
  text-align: center;
  height: 20px !important; /* Adjust the height as needed */
  padding: 7px; /* Adjust the padding as needed */
  line-height: 25px; /* Match the line height to the height for vertical centering */
}

.p-datatable .p-column-header-content {
  justify-content: center;
  font-size: 0.8rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 0.4rem 0.3rem;
}

.p-datatable .p-column-header-content .p-column-filter{
  display: inline-flex;
  margin-left: -5px;
}

/* Styling DataTable rows */
.custom-datatable .p-datatable-tbody > tr {
  background-color: #ffffff; /* Background color */
  color: #555; /* Text color */
  border: 1px solid #ccc; /* Border */

}

/* Alternate row background color */
.custom-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternate background color */
}

/* Custom styles for specific columns */
.custom-column{
  text-align: center !important; /* Align text center */
  font-size: 0.75rem;
}

/* .p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.3rem;
  height: 0.8rem;
} */

/* Hide the sort icon initially */
.custom-datatable .p-sortable-column .p-sortable-column-icon   {
  display: none;
  color: #6c757d;
  margin-left: 0.3rem;
  height: 0.8rem;
}

/* Show the sort icon on hover */
.custom-datatable .p-sortable-column:hover .p-sortable-column-icon  {
  display: inline-block;
}

.p-icon {
  width: 0.7rem;
  height: 0.9rem;
}


.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #e5e7eb;
  color: #374151;
  background: #f9fafb;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1.25rem;
  border-bottom: 1px solid #e5e7eb;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}

 .p-dropdown {
  background: #ffffff;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  outline-color: transparent;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-inputtext {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: 1rem;
  color: #4b5563;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  outline-color: transparent;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #6b7280;
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.75rem 1.25rem;
}

.p-button.p-button-text {
  font-size: 0.875rem !important;
  padding: 0.65625rem 1.09375rem;
  background-color: transparent;
  color: #097969;
  border-color: transparent;
  font-size: 12px;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1.25rem;
}

.p-column-filter-buttonbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-button {
  color: #ffffff;
  background: #097969;
  border: 1px solid #097969;
  padding: 0.35rem 0.25rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}

.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 1.09375rem;
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: #097969;
  border: 1px solid;
}

.p-icon-field-left > .p-input-icon:first-of-type {
  left: 0.75rem;
  color: #6b7280;
}

.p-icon-field > .p-input-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-icon-field-left > .p-inputtext {

  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: 1rem;
  color: #4b5563;
  background: #ffffff;
  padding: 0.45rem 0.45rem 0.45rem 2.5rem;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  outline-color: transparent;
}
.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 1rem;
}


.inputText {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: 1rem;
  color: #4b5563;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  outline-color: transparent;
}

.p-overlaypanel .p-overlaypanel-close {
  background: #097969;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  left: -1rem;
}

.p-overlaypanel{
  /* padding: 0.75rem 1.25rem; */
  border-bottom: 1px solid #e5e7eb;
  color: #374151;
  background: #f9fafb;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-overlaypanel .p-button .p-button-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 1.09375rem;
}

.buttonStyle{
  font-size: 0.875rem;
  padding: 0.65625rem 1.09375rem;
}

.buttonBar{
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.css-1ell3cv {
  padding: 10px !important;
}

.p-paginator {
  padding: 0;
 
}

.button_font_size{
  font-size: 0.75rem !important;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 3px;
  top: 100px !important;
}


