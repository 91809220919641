.accordian_heading{
    background-color: #D9D9DC !important;
    min-height: 44px !important;
}

.button_pos{
    top: 0;
    right: 0;
}

.logo_divider{
    border-width: 2px;
    border-color: #D9D9DC !important;
    border-style: solid;
    width: 100% ;
}

.acc_bg{
    background-color: #F6F6F7;
}

.upload_icon{
    font-size: 2.5rem !important;
    color: #fff;
}

.submit_container {
    position: sticky;
    bottom: 0;
    background-color: #f0f0f0;
    padding: 10px;
    text-align: center;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Shadow for the sticky footer */
}

.submit_button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}

.submit_button:hover {
    background-color: #0056b3;
  }

.fields_padding{
    padding-top: 0px !important;
}

.label_margin{
    margin-right: 10px !important;
    font-weight: 700 !important;
}

.label_font_size {
    font-size: 0.9rem !important;
    word-wrap: break-word;
}

.table {
    width: 100%;
    border-collapse: collapse; /* Ensures table borders are collapsed */
    border: 1px solid #ccc; /* Overall border for the table */
  }
  
.table th,
.table td {
  border: 1px solid #ccc; /* Borders for table header and cells */
  padding: 8px;
  text-align: left;
}

.table th{
    font-size: 15px;
}

.active{
    color: #000 !important;
    font-weight: bold;
}
.active_back{
    background-color: #09796867 !important;
}

.fab_position{
    position: fixed !important;
}